<template>
  <v-app id="inspire">
    <Toolbar />
    <v-main class="v-sheet theme--light">
       <router-view :key="$route.fullPath"></router-view>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>

import Footer from "@/components/carbon-ui/footer/Footer4.vue";
import Toolbar from "@/components/carbon-ui/toolbar/Toolbar2_backoffice.vue";

export default {
  name: "Layout",
  components: {
    Footer,
    Toolbar
  }
}
</script>


