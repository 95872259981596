<template>
  <v-app id="inspire">
    <Toolbar />
    <v-main class="v-sheet theme--light">
      <div v-if="missingProfile" class="missing-profile text-center">
        <h4>Complete your personal profile to receive recommendations about training courses and job offers</h4>
      </div>
      <router-view :key="$route.fullPath" @missingProfile="onMissingProfile" ></router-view>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from "@/components/carbon-ui/footer/Footer4.vue";
import Toolbar from "@/components/carbon-ui/toolbar/Toolbar2.vue";

export default {
  name: "Layout",
  components: {
    Footer,
    Toolbar,
  },
  data: () => ({
    missingProfile: false
  }),
  methods: {
    onMissingProfile (value) {
      this.missingProfile = value
    }
  }
};
</script>

<style scoped>
.missing-profile {
  height: 25px;
  color: white;
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}
</style>

