<template>
  <div>
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list dense nav>
        <v-subheader class="text-uppercase font-weight-bold">Menu</v-subheader>
        <v-list-item>
          <v-list-item-content>
            <v-list-item link to="/app/company/user-profile">
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>

            <v-list-item link to="/app/company/search-courses">
              <v-list-item-title>Training Courses</v-list-item-title>
            </v-list-item>

            <v-list-item link to="/app/company/look-for-candidates">
              <v-list-item-title>Candidates</v-list-item-title>
            </v-list-item>

            <v-list-item link to="/app/company/my-courses">
              <v-list-item-title>Training Courses</v-list-item-title>
            </v-list-item>

            <v-list-item link to="/app/company/offer-jobs">
              <v-list-item-title>Offer Jobs</v-list-item-title>
            </v-list-item>

            <v-list-item link to="/app/company/rate-training-courses">
              <v-list-item-title>Rate Courses</v-list-item-title>
            </v-list-item>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-btn class="d-md-none drawer-button" rounded @click="drawer = !drawer">
      <v-icon right>mdi-menu</v-icon>
    </v-btn>

    <v-app-bar app height="80" class="d-none d-md-flex">
      <v-container class="py-0 px-0 px-sm-2 fill-height" :fluid="isFluid">
        <router-link
          to="/app/company/home"
          class="d-flex align-center text-decoration-none mr-2"
        >
          <img src="/images/allview-logo.png" alt="" height="48" class="mr-1" />
          <span class="font-weight-black text-uppercase text-gradient">
            Allview Platform
          </span>
        </router-link>

        <div class="d-none d-md-block">
          <v-btn text to="/app/company/user-profile" class="mx-1">
            <div class="text-gradient-half">Profile</div>
          </v-btn>

          <v-menu open-on-hover offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on"
                ><div class="text-gradient-half">Explore</div></v-btn
              >
            </template>
            <v-list>
              <v-list-item link to="/app/company/search-courses">
                <v-list-item-title class="text-gradient-half"
                  >Training Courses</v-list-item-title
                >
              </v-list-item>
              <v-list-item link to="/app/company/look-for-candidates">
                <v-list-item-title class="text-gradient-half"
                  >Candidates</v-list-item-title
                >
              </v-list-item>

              <v-list-item link to="/app/company/search-jobs">
                <v-list-item-title class="text-gradient-half"
                  >Job Offers</v-list-item-title
                >
              </v-list-item>

            </v-list>
          </v-menu>

          <v-menu open-on-hover offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on"
                ><div class="text-gradient-half">Favourites</div></v-btn
              >
            </template>
            <v-list>
              <v-list-item link to="/app/company/my-courses">
                <v-list-item-title class="text-gradient-half"
                  >Training Courses</v-list-item-title
                >
              </v-list-item>
            </v-list>
            <v-list>
              <v-list-item link to="/app/company/my-candidates">
                <v-list-item-title class="text-gradient-half"
                  >Candidates</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn text to="/app/company/offer-jobs" class="mx-1">
            <div class="text-gradient-half">Offer Jobs</div>
          </v-btn>

          <v-btn text to="/app/company/rate-training-courses" class="mx-1">
            <div class="text-gradient-half">Rate Courses</div>
          </v-btn>
        </div>

        <v-spacer></v-spacer>

        <v-btn large text class="mx-1" to="/app/company/settings">
          <div class="text-gradient-half">Settings</div>
        </v-btn>
        <v-btn large class="button-gradient-half" @click="logOut()">
          Logout
        </v-btn>
      </v-container>
    </v-app-bar>
    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top>
      <v-icon>{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    isFluid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: null,
      // Snackbar
      snackbar_show: false,
      snackbar_color: null,
      snackbar_text: null,
      snackbar_icon: null,
    };
  },
  methods: {
    async logOut() {
      // Delete the auth token
      this.$cookie.delete("jwt_token");

      // Logout message
      this.snackbar_color = "success";
      this.snackbar_text = "Success: you have been logged out";
      this.snackbar_icon = "mdi-check";
      this.snackbar_show = true;

      // Keep the user waiting to see the success message
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Redirect the user to the login page
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.drawer-button {
  position: fixed;
  top: 40px;
  right: -20px;
  z-index: 6;
}

.button-gradient-half:hover {
  background-position: right center;
}

.button-gradient-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}

.text-gradient {
  background: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-half {
  background: linear-gradient(to right, #047ec3 0%, #52ae32 150%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
